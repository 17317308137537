import React from 'react'
import './App.css';


const ENTREES=[
  {
    des:"Poêlée de gambas sur lentilles au curry",
    price:"17.00"
  },
  {
    des:"Noix de St Jacques bretonne sur lentilles au curry",
    price:"20.00"
  },
  {
    des:"Cuisses de grenouilles à l'ail",
    price:"15.00"
  },
  {
    des:"Terrine de foie gras d'oie, pain brioché, confiture de figues",
    price:"18.00"
  },
  {
    des:"Les croquettes aux crevettes grises",
    price:"18.00"
  },
  {
    des:"Ris de veau poêlés aux morilles",
    price:"20.00"
  },
  {
    des:"Les croquettes au parmesan",
    price:"15.00"
  },
]

const PLATS=[
  {
    des:"Filet de bar, légumes oubliés, émulsion aux crevettes grises",
    price:"30.00"
  },
  {
    des:"Magret de cannette de barbarie à l'orange",
    price:"29.00"
  },
  {
    des:"Pigeonneau d'Anjou, pommes amandine, garniture d'automne",
    price:"35.00"
  },
  {
    des:"Filet pur de boeuf Irlandais, sauce poivres concassés, pommes frites",
    price:"33.00"
  },
  {
    des:"Carré d'agneau rôti en croûte d'herbes, jus à la moutarde",
    price:"30.00"
  },
  {
    des:"Dos de cabillaud rôti au jambon de parme, beurre aux câpres",
    price:"29.00"
  },
  {
    des:"Filet pur de boeuf Irlandais aux morilles, pommes frites",
    price:"38.00"
  },
  

]

const DESSERTS=[
  {
    des:"Meringue aux fruits rouges, glace vanille",
    price:"9.00"
  },
  {
    des:"Moelleux au chocolat glace vanille",
    price:"9.00"
  },
  {
    des:"Crumble pommes et poires, glace vanille",
    price:"9.00"
  },
  {
    des:"Crème brûlée",
    price:"9.00"
  },
  {
    des:"Dame blanche",
    price:"10.00"
  },
  {
    des:"Assiette de fromages",
    price:"10.00"
  },
]




const HorizontalLine = ({ width, color }) => {
  const lineStyle = {
    width: width || '100%', 
    height: '1px',      
    backgroundColor: color || 'white', 
    margin: '10px 0',      
  };

  return <div style={lineStyle}></div>;
};

const CarteComponent=(des, price)=>{

  return(
    <div style={{marginTop:-10,justifyContent:"space-between", flexDirection:"row", display:"flex", width:"100%"}}>
      <p style={{maxWidth:"80%"}}>{des}</p>
      <p style={{fontSize:18}}>{price}€</p>

    </div>
  )
}
function Carte() {
  return (
    
    <div className="origin">
       <title>Carte</title>
       <meta name="description" content="Notre carte"/>
    <div className="gallery-wrapper">
    
  
    <div className="gallery" style={{justifyContent:'center', display:"flex", color:"white", fontFamily:"-moz-initial", fontSize:20, flexDirection:"column", alignItems:"center", width:"100%"}}>
   
     


     {/* MENU */}
     <p style={{ fontFamily:'fantasy',fontSize:22, textAlign:"center"}}>Menu<br/>45€</p>
     
     <div style={{marginTop:-20, fontFamily:"-moz-initial", fontSize:20, alignItems:'center', justifyContent:"center", display:'flex', flexDirection:"column", maxWidth:"80%", textAlign:"center"}}>
     <p>Mises en bouche</p>
     <HorizontalLine width="20%"  />
     <p>Ris de veau de coeur aux morilles</p>

    <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Poêlée de gambas sur lentilles au curry</p>
      {/*
     <HorizontalLine width="20%"  />

     <p>Foie gras d'oie poêlé aux raisins</p>
     
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Dos de cabillaud, pommes purée de brocolis beurre aux tomates cerises</p>*/}

     <HorizontalLine width="20%"  />
     <p>Dos de cabillaud, légumes oubliés, émulsion aux crevettes grises</p>
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Magret de cannette de barbarie à l'orange</p>

     <HorizontalLine width="20%"  />
     <p>Dessert OU Fromages</p>

     </div>


    
   

      {/* Carte */}
     <p style={{fontFamily:'fantasy',fontSize:22}}>Carte</p>

     <p style={{fontFamily:"fantasy", fontSize:18, marginTop:20, alignSelf:"flex-start", marginLeft:"10%"}}>Entrées</p>
     <div style={{fontFamily:"-moz-initial",fontSize:20, display:'flex', flexDirection:"column", width:"80%", marginTop:-20}}>

     {ENTREES.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>



     <p style={{ fontFamily:"fantasy", fontSize:18, alignSelf:"flex-start", marginLeft:"10%"}}>Plats</p>
     <div style={{fontFamily:"-moz-initial", fontSize:20, display:'flex', flexDirection:"column", width:"80%",marginTop:-20}}>

     {PLATS.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>

     <p style={{ fontFamily:"fantasy", fontSize:18, alignSelf:"flex-start", marginLeft:"10%"}}>Desserts</p>
     <div style={{fontFamily:"-moz-initial", fontSize:20, display:'flex', flexDirection:"column", width:"80%",marginTop:-20}}>

     {DESSERTS.map((a, index) => (
            CarteComponent(a.des, a.price)
          ))}

     </div>

    </div>
    </div>
    </div>
  );
}

export default Carte
